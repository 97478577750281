import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: 'https://771189855b5b41e595be939ae909a7bb@o128574.ingest.sentry.io/4504133315526656',
  environment: process.env.GATSBY_ENVIRONMENT,
  sampleRate: 1.0,
  beforeSend(event) {
    if (event.user) {
      delete event.user.email
    }
    return event
  },
})
